import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { ChartsComponent } from './charts/charts.component';
import { UserStatsComponent } from './user-stats/user-stats.component';
import { CosmosComponent } from './cosmos/cosmos.component';
import { TestingComponent } from './testing/testing.component';
import { RegisterComponent } from './register/register.component';
import { AdminComponent } from './admin/admin.component';
import { AcademyComponent } from './academy/academy.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SeoComponent } from './seo/seo.component';
import { Seo1Component } from './seo1/seo1.component';
import { Seo2Component } from './seo2/seo2.component';
import { Seo3Component } from './seo3/seo3.component';
import { Seo4Component } from './seo4/seo4.component';
import { Seo5Component } from './seo5/seo5.component';
import { Seo6Component } from './seo6/seo6.component';
import { Seo7Component } from './seo7/seo7.component';
import { Seo8Component } from './seo8/seo8.component';
import { Seo9Component } from './seo9/seo9.component';
import { Seo10Component } from './seo10/seo10.component';
import { Seo11Component } from './seo11/seo11.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ErrorComponent } from './error/error.component';
import { JoinusintermediateComponent } from './joinusintermediate/joinusintermediate.component';
import { RiskInfomartionComponent } from './risk-infomartion/risk-infomartion.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { NewLandingComponent } from './new-landing/new-landing.component';

const routes: Routes =[


    // { path: '', redirectTo: '', pathMatch: 'full' },
    //{ path: 'questionnaire',  component: QuestionnaireComponent },
   // { path: 'multimedia',     component: MultimediaComponent },
    { path: 'login',          component: LoginComponent},
    { path: 'etoro',          component: ChartsComponent},
    { path: 'user',           component: UserStatsComponent},
    { path: 'cosmos',         component: CosmosComponent},
    { path: '',           component: TestingComponent},
    { path: 'home',           component: TestingComponent},
    { path: 'register',       component: RegisterComponent},
    { path: 'academy',      component: AcademyComponent},
    { path: 'newsletter',    component: NewsletterComponent},
    { path: 'information/about-backseat-trader-and-etoro', component: SeoComponent },
    { path: 'information/smart-stock-curation', component: Seo1Component },
    { path: 'information/advanced-analytics-and-intelligence', component: Seo2Component },
    { path: 'information/diversified-approach', component: Seo3Component },
    { path: 'information/methodology', component: Seo4Component },
    { path: 'information/methodology/runs-on-daily-candles', component: Seo5Component },
    { path: 'information/methodology/absolute-momentum-quantification-mechanism-based-on-13612-weighted-filter', component: Seo6Component },
    { path: 'information/methodology/relative-momentum-ranking-and-weight-allocation-system', component: Seo7Component },
    { path: 'information/methodology/support-for-stock-and-etf-portfolios', component: Seo8Component },
    { path: 'information/methodology/support-for-leveraged-and-unleveraged-accounts', component: Seo9Component },
    { path: 'information/methodology/adjustable-rebalance-period', component: Seo10Component },
    { path: 'information/how-the-backseat-trader-algorithm-was-developed', component: Seo11Component },
    { path: 'sitemap', component: SitemapComponent },
    { path: 'join-us', component: JoinusintermediateComponent },
    { path: 'information/risk', component: RiskInfomartionComponent},
    { path: 'privacy', component: PrivacyComponent},
    { path: 'cookies', component: CookiesComponent},
    { path: 'disclaimer', component: DisclaimerComponent},
    {path: 'xyz', component: NewLandingComponent}
    // { path: '**', pathMatch: 'full', component: ErrorComponent}
  
   // { path: 'admin',          component: AdminComponent}
    // { path: '', redirectTo: 'home', pathMatch: 'full' }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      // useHash: true
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
