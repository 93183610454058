import { Directive, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';


@Directive({
  selector: '[scrolledTo]',
  exportAs: 'scrolledTo',
})
export class ScrolledToDirective {

  reached = false;
  passed = false;
  notreached = true;
  passedChecker = false;

  reachedThree = false;
  reachedEtoro = false;
  reachedBrochure = false;
  reachedBubbles = false;

  passedThree = false;
  passedEtoro = false;
  passedBrochure = false;
  passedBubbles = false;

  notThree = true;
  notBrochure = true;
  notEtoro = true;
  notBubbles = true;

  bubblesDone = false;

  urlAbout = false;
  urlPerf = false;
  urlBrochure = false;

  reachedColumn = false;
  passedColumn = true;
  columnsDone = false;

  mobile: Boolean = false;
  constructor(public el: ElementRef, public location: Location, public router: Router) { }
  
  

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

    const elementPosition = this.el.nativeElement.offsetTop;
    const elementHeight = this.el.nativeElement.clientHeight;
    const scrollPosition = window.scrollY;

    // console.log(scrollPosition)
    // console.log(elementPosition)
    // console.log(this.el.nativeElement.id)

    // if(!this.passedThree){
    //   this.reachedThree = scrollPosition >= elementPosition;
    //   this.passedThree = scrollPosition >= (elementPosition + elementHeight) + 1000;

    // }
    
    // if(this.passedThree){
    //   this.reachedThree = false;
    //   this.notThree = false;
    // }

    // if(!this.passedEtoro){
    //   this.reachedEtoro = scrollPosition >= elementPosition;
    //   this.passedEtoro = scrollPosition >= (elementPosition + elementHeight) + 1000;

    // }
    
    // if(this.passedEtoro){
    //   this.reachedEtoro = false;
    //   this.notEtoro = false;
    // }

    // if(!this.passedBrochure){
    //   this.reachedBrochure = scrollPosition >= elementPosition;
    //   this.passedBrochure = scrollPosition >= (elementPosition + elementHeight) + 1000;

    // }
    
    // if(this.passedBrochure){
    //   this.reachedBrochure = false;
    //   this.notBrochure = false;
    // }
    // console.log(this.router.url)

    if(scrollPosition >= 800 && scrollPosition <= 1850){
      this.reachedThree = true;
      if(this.urlAbout == false){
        // this.location.replaceState('/#about');
        this.urlAbout = true;
      }
    } else{
      this.reachedThree = false;
      // console.log(this.router.url)
      // this.location.replaceState("/");
    }

    if(scrollPosition >= 1850 && scrollPosition <= 5100){
      this.reachedEtoro = true;
      if(this.urlPerf == false){
        // this.location.replaceState("/#performance");
        this.urlPerf = true;
      }
    } else{
      this.reachedEtoro = false;
      // console.log(this.router.url)
    }

    if(this.bubblesDone == false){
    if(scrollPosition >= 5200 && scrollPosition <= 6000){
      this.reachedBubbles = true;
      setTimeout(() => (this.reachedBubbles = false), 3000);
      this.bubblesDone = true;
    }
    
    }

    if(this.columnsDone == false){
      let scrollPosMin = 0;
      let scrollPosMax = 0;
      if(window.location.pathname == '/xyz'){
        if(this.mobile == true){
          scrollPosMin = 3450;
          scrollPosMax = 4500; 
        }else if(this.mobile == false){
          scrollPosMin = 2100;
          scrollPosMax = 2400;
        }
        let count = 0; // track the number of cycles
        const maxCycles = 4; // define the number of cycles
      
        let intervalID1 = setInterval(() => {
          // const element = document.getElementById("howItWorksIcon");
      
          if(scrollPosition >= scrollPosMin && scrollPosition <= scrollPosMax){
            this.reachedColumn = true;
      
            // Remove the class after 1 second
            setTimeout(() => {
              this.reachedColumn = false;

            }, 1000);
      
            count++;
      
            // Stop the interval after the specified number of cycles
            if (count === maxCycles) {
              clearInterval(intervalID1);
              this.columnsDone = true;

            }
          }
        }, 2000); // 2 seconds interval to allow time for both add and remove
      }
    }


    if(scrollPosition >= 6400){
      this.reachedBrochure = true;
      if(this.urlBrochure == false){
        // this.location.replaceState("/#brochure");
        this.urlBrochure = true;
      }
    } else{
      this.reachedBrochure = false;
      // this.location.replaceState("/");
    }

    // set `true` when scrolling has reached current element
    this.reached = scrollPosition >= elementPosition;

    // set `true` when scrolling has passed current element height
    this.passed = scrollPosition >= (elementPosition + elementHeight);

    if(this.passed == true){
      // console.log("passed");
    }

    if(this.reached == true){
      // console.log("reached")
      this.notreached = false;
    }else if(this.reached == false){
      this.notreached = true;
    }

    // if(!this.reachedBubbles && !this.reachedBrochure && !this.reachedEtoro && !this.reachedThree){
    //   this.location.replaceState("/");
    //   console.log("here!!")
    // }

    if(this.mobile){
      if(this.bubblesDone == false){
        if(scrollPosition >= 4430 && scrollPosition <= 5000){
          this.reachedBubbles = true;
          setTimeout(() => (this.reachedBubbles = false), 3000);
          this.bubblesDone = true;
        }
      }
    }

    // console.log(scrollPosition)
  }
}
